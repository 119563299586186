import { Box } from '@chakra-ui/react';
import React, { FC } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { OtlcService } from '../community-service/otlc-service';
import { useCommunityAuth } from '../components/auth';
import { auth } from '../components/firebase';
import { Spinner } from '../styles/Spinner';

const otlc = OtlcService();

export const VerifyOtlc: FC = () => {
    let { code } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const { signInWithCustomToken } = useCommunityAuth();

    React.useEffect(() => {
        const signIn = async () => {
            if (code) {
                const customToken = await otlc.getCustomToken(code);
                if (customToken) {
                    const credentials = await signInWithCustomToken(auth, customToken);
                    return credentials;
                }
            }
            return undefined;
        };

        signIn()
            .then(() => {
                setTimeout(() => {
                    navigate({
                        pathname: '/',
                        search: location.search,
                    });
                }, 1000);
            })
            .catch((err) => console.error(err));
    }, []);
    return (
        <Box display="flex" height="100vh" justifyContent="center" alignItems="center">
            <Spinner />
        </Box>
    );
};
